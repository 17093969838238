import { useMsal } from '@azure/msal-react';

import React, { useEffect } from 'react';
import { loginRequest } from '../../config/authConfig';
import './LoginComponent.css';

//Material UI
import CircularProgress from '@mui/material/CircularProgress';

export default function LoginComponent() {
  const { instance } = useMsal();
  //const username = instance[0].username;

  useEffect(() => {
    instance.loginRedirect(loginRequest);
  }, []);

  return (
    <div className="login-component">
      <div className="home-welcome-header">Welcome to FindHuddle</div>
      <CircularProgress
        size={50}
        style={{ color: '#0c69b0', marginTop: '30px' }}
      />
    </div>
  );
}
