import React, { useState, useEffect } from 'react';
import './style.css';

export default function CardStatus({ room, status }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div className="card-status">
      <div className={`status ${status ? 'available' : 'occupied'}`}>
        <div>{width > 767 ? null : status ? 'Available' : 'Occupied'}</div>
      </div>
      <div className="card-desc">
        <div className="room">{room}</div>
        {width > 767 ? (
          <div className={`room-status ${status ? 'available' : 'occupied'}`}>
            {status ? 'Available' : 'Occupied'}
          </div>
        ) : null}
      </div>
    </div>
  );
}
