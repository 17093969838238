import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="home-page">
      <div className="home-welcome-header">Welcome to FindHuddle</div>
      {/* <div className="home-welcome-text">
        Find vacant huddle nearby, at one click (bookmark us?)
      </div> */}
      <div className="home-building-list">
        <div className="home-floor-list">
          <h2 className="building-name">Harindhorn building</h2>
          <div className="floors">
            <Link className="floor" to="/buildings/hrh/gfloor">
              G Floor
            </Link>
          </div>
          <div className="floors">
            <Link className="floor" to="/buildings/hrh/3rdfloor">
              3<sup>rd</sup> Floor
            </Link>
          </div>
          <div className="floors">
            <Link className="floor" to="/buildings/hrh/4thfloor">
              4<sup>th</sup> Floor
            </Link>
          </div>
          {/*
          <div className="floors">
		    <Link className="floor" to="/buildings/hrh/6thfloor" >
              6<sup>th</sup> Floor
			</Link>
          </div>
          <div className="floors">
		    <Link className="floor" to="/buildings/hrh/8thfloor" >
              8<sup>th</sup> Floor
			</Link>
          </div>
		  */}
          <div className="floors">
            <Link className="floor" to="/buildings/hrh/11thfloor">
              11<sup>th</sup> Floor
            </Link>
          </div>
          <div className="floors">
            <Link className="floor" to="/buildings/hrh/12thfloor">
              12<sup>th</sup> Floor
            </Link>
          </div>
        </div>
        {/* <div className="home-floor-list">
          <h2 className="building-name">Q House Lumpini Building</h2>
          <div className="floors">
            <Link className="floor" to="/buildings/qhl/21stfloor">
              21<sup>st</sup> Floor
            </Link>
          </div>
        </div> */}
        {/* <div className="home-floor-list">
          <h2 className="building-name">Vibulthani Tower</h2>

          <div className="floors">
            <Link className="floor" to="/buildings/vtt/21stfloor">
              21<sup>st</sup> Floor
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Home;
