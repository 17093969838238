import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

import React from 'react';
import Container from '../container/Container';
import LoginPage from '../../pages/login/LoginPage';
import Navbar from '../navbar/Navbar';
import './PageLayout.css';

export const PageLayout = ({ children }) => {
  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <main className="body-main">
      <Navbar />
      <Container>
        <UnauthenticatedTemplate>
          <LoginPage />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      </Container>
    </main>
  );
};
