import axios from 'axios';
import { backendUri } from '../config/authConfig';

class Adapter {
  instance;
  accessToken;

  constructor() {
    this.instance = axios.create({
      baseURL: backendUri.endpoint,
      timeout: 60000,
      withCredentials: true,
    });
  }
}

const adapter = new Adapter();

export default adapter;
