export const sidebarList = [
  {
    title: 'HRH',
    subNav: [
      {
        title: 'G Floor',
        floor: 'G',
        path: '/buildings/hrh/gfloor',
      },
      {
        title: '3rd Floor',
        floor: '3',
        path: '/buildings/hrh/3rdfloor',
      },
      {
        title: '4th Floor',
        floor: '4',
        path: '/buildings/hrh/4thfloor',
      },
      //    {
      //         title: '6th Floor',
      //         path: '/buildings/hrh/6thfloor'
      //     },
      // {
      //     title: '8th Floor',
      //     path: '/buildings/hrh/8thfloor'
      // },
      {
        title: '11th Floor',
        floor: '11',
        path: '/buildings/hrh/11thfloor',
      },
      {
        title: '12th Floor',
        floor: '12',
        path: '/buildings/hrh/12thfloor',
      },
    ],
  },
  /*  title: 'QHL',
        subNav: [
            {
                title: '21st Floor',
                path: '/buildings/qhl/21stfloor'
            }
            
        ]
    }, */
  /* {
       title: 'VTT',
        subNav: [
            {
                title: '21st Floor',
                path: '/buildings/vtt/21stfloor'
            }

        ]
    },*/
  /* {
        title: 'EXPORT',
        subNav: [
            {
                title: 'Export files',
                path: '/export'
            }
        ]
    } */
];

export const sidebarListInverted = [
  {
    title: 'HRH',
    subNav: [
      //    {
      //         title: '6th Floor',
      //         path: '/buildings/hrh/6thfloor'
      //     },
      // {
      //     title: '8th Floor',
      //     path: '/buildings/hrh/8thfloor'
      // },
      {
        title: '12th Floor',
        floor: '12',
        path: '/buildings/hrh/12thfloor',
      },
      {
        title: '11th Floor',
        floor: '11',
        path: '/buildings/hrh/11thfloor',
      },
      {
        title: '4th Floor',
        floor: '4',
        path: '/buildings/hrh/4thfloor',
      },
      {
        title: '3rd Floor',
        floor: '3',
        path: '/buildings/hrh/3rdfloor',
      },
      {
        title: 'G Floor',
        floor: 'G',
        path: '/buildings/hrh/gfloor',
      },
    ],
  },
];
