import React from 'react';

//Material UIs
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

//Loading
import LoadingGearGrey from '../../svgs/LoadingGearGrey.svg';
import LoadingGearRed from '../../svgs/LoadingGearRed.svg';

import './UnderConstrucitonPage.css';

function UnderConstructionPage() {
  return (
    <>
      <nav className="nav">
        <div className="nav-logo-section">FindHuddle</div>
      </nav>
      <Container style={{ paddingTop: '150px' }}>
        {/* ========================== MOBILE ========================== */}
        <Grid item sx={{ display: { sm: 'none', xs: 'block' } }} xs={12}>
          <Grid container spacing={2}>
            <Grid item align="center" xs={12}>
              <Grid container justifyContent="center">
                <Grid item align="center">
                  <object type="image/svg+xml" data={LoadingGearGrey}>
                    svg-animation
                  </object>
                </Grid>
                <Grid
                  item
                  align="center"
                  sx={{ marginLeft: '-30px', marginTop: '70px' }}
                >
                  <object type="image/svg+xml" data={LoadingGearRed}>
                    svg-animation
                  </object>
                </Grid>
              </Grid>
            </Grid>
            <Grid item align="center" xs={12}>
              <Typography variant="h4" style={{ fontWeight: 700 }}>
                Under Construction
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 500 }}>
                FindHuddle will be back soon!
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* ========================== WEB ========================== */}
        <Grid item sx={{ display: { xs: 'none', sm: 'block' } }} xs={12}>
          <Grid container spacing={2}>
            <Grid item align="center" xs={12}>
              <Grid container justifyContent="center">
                <Grid item align="center">
                  <object type="image/svg+xml" data={LoadingGearGrey}>
                    svg-animation
                  </object>
                </Grid>
                <Grid
                  item
                  align="center"
                  sx={{ marginLeft: '-20px', marginTop: '100px' }}
                >
                  <object type="image/svg+xml" data={LoadingGearRed}>
                    svg-animation
                  </object>
                </Grid>
              </Grid>
            </Grid>
            <Grid item align="center" xs={12}>
              <Typography
                variant="h3"
                style={{ fontWeight: 700, color: '#dddddd' }}
              >
                Under Construction
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                FindHuddle will be back soon!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default UnderConstructionPage;
