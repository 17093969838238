import React from 'react';
import * as AiIcons from 'react-icons/ai';
import SidebarMenu from '../sidebarMenu/SidebarMenu';
import './Sidebar.css';
import { sidebarListInverted } from '../../constant/sidebarList';

const Sidebar = ({ sidebarVisible, setSidebarVisible }) => {
  return (
    <>
      {/*<nav className={`sidebar ${sidebarVisible ? 'open' : 'close'}`}>*/}
      <nav className={`sidebar open`}>
        <div className="sidebar-close-section">
          <AiIcons.AiOutlineClose
            onClick={() => setSidebarVisible(!sidebarVisible)}
            className="sidebar-close-icon"
          />
        </div>
        {sidebarListInverted.map((item, index) => {
          return (
            <SidebarMenu
              item={item}
              key={index}
              hideSidebar={() => setSidebarVisible(!sidebarVisible)}
            />
          );
        })}
      </nav>
    </>
  );
};

export default Sidebar;
