import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import adapter from '../../services/adapter.service';
import './SidebarMenu.css';

import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources, loginRequest } from '../../config/authConfig';

//MaterialUI
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const SidebarMenu = ({ item, hideSidebar }) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  if (account && inProgress === 'none' && !adapter.accessToken) {
    instance
      .acquireTokenSilent({
        scopes: protectedResources.devicesApi.scopes,
        account: account,
      })
      .then((response) => {
        adapter.accessToken = response.accessToken;
      })
      .catch((error) => {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError) {
          if (account && inProgress === 'none') {
            instance
              .acquireTokenPopup({
                scopes: protectedResources.devicesApi.scopes,
              })
              .then((response) => {
                adapter.accessToken = response.accessToken;
              })
              .catch((error) => console.log(error));
          }
        }
      });
  }

  const data = useQuery(
    `getRoomsInfo`,
    () =>
      adapter.instance
        .get(`/api/devices`, {
          headers: { Authorization: 'Bearer ' + adapter.accessToken },
        })
        .then((res) => res.data),
    {
      // Refetch the data every 10 seconds
      refetchInterval: '10000',
    }
  );

  const checkIsFull = (data, floor) => {
    try {
      if (data === undefined) {
        return;
      }
      if (
        data
          .filter((obj) => obj.floor === floor)
          .filter((obj) => new Date() - new Date(obj.lastOnline) > 120000)
          .length === 0
      ) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      instance.loginRedirect(loginRequest);
    }
  };

  return (
    <section className="sidebar-menu">
      {item.subNav.map((item, index) => {
        return (
          <Link to={item.path} key={index} style={{ textDecoration: 'none' }}>
            <span className="menu-item" onClick={hideSidebar}>
              <Grid container style={{ paddingRight: '20px' }}>
                <Grid item align="left" xs>
                  {item.floor}
                  <span style={{ fontSize: '20px' }}>F</span>
                </Grid>
                <Grid item align="right" xs>
                  <Button
                    disabled
                    disableElevation
                    variant="contained"
                    style={{
                      width: '50px',
                      color: 'white',
                      backgroundColor: checkIsFull(data.data, item.floor)
                        ? '#BD3D3D'
                        : '#559E3B',
                    }}
                  >
                    {data.data ? (
                      checkIsFull(data.data, item.floor) ? (
                        'Full'
                      ) : (
                        data?.data
                          ?.filter((obj) => obj.floor === item.floor)
                          .filter(
                            (obj) =>
                              new Date() - new Date(obj.lastOnline) > 120000
                          ).length
                      )
                    ) : (
                      <CircularProgress size={26} style={{ color: 'white' }} />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </span>
          </Link>
        );
      })}
    </section>
  );
};

export default SidebarMenu;
