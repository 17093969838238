import React from 'react';
import './FindHuddleLayout.css';
import * as AiIcons from 'react-icons/ai';
import { useQuery } from 'react-query';
import adapter from '../../services/adapter.service';
import CardStatus from '../cardStatus/CardStatus';

import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources, loginRequest } from '../../config/authConfig';

export default function FindHuddleLayout({
  buildingTitle,
  map,
  buildingCode,
  floor,
}) {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  if (account && inProgress === 'none' && !adapter.accessToken) {
    instance
      .acquireTokenSilent({
        scopes: protectedResources.devicesApi.scopes,
        account: account,
      })
      .then((response) => {
        adapter.accessToken = response.accessToken;
      })
      .catch((error) => {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError) {
          if (account && inProgress === 'none') {
            instance
              .acquireTokenPopup({
                scopes: protectedResources.devicesApi.scopes,
              })
              .then((response) => {
                adapter.accessToken = response.accessToken;
              })
              .catch((error) => console.log(error));
          }
        }
      });
  }

  const {
    isLoading,
    error,
    data: rooms,
  } = useQuery(
    `getRoomsInfo-${buildingCode}-${floor}`,
    () =>
      adapter.instance
        .get(`/api/devices/${buildingCode}/${floor}`, {
          headers: { Authorization: 'Bearer ' + adapter.accessToken },
        })
        .then((res) => res.data),
    {
      // Refetch the data every 10 seconds
      refetchInterval: '10000',
    }
  );

  if (isLoading)
    return (
      <div className="find-huddle-layout">
        <AiIcons.AiOutlineLoading3Quarters className="icon-spin find-huddle-loading" />
      </div>
    );

  if (error) {
    console.log('An error has occurred: ' + error.message);
    instance.loginRedirect(loginRequest);
    return;
  }

  return (
    <div className="find-huddle-layout">
      <h1>{buildingTitle}</h1>
      <div className="find-huddle-view">
        <div className="map-layout">{map ? map(rooms) : null}</div>
        <div className="room-info-layout">
          {rooms.map(({ room, lastOnline }) => {
            return (
              <CardStatus
                room={room}
                status={new Date() - new Date(lastOnline) > 120000}
                key={`card-status-${room}`}
              ></CardStatus>
            );
          })}
        </div>
      </div>
    </div>
  );
}
