import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PageLayout } from './components/pageLayout/PageLayout';
import { QueryClientProvider } from 'react-query';
import queryClient from './services/queryClient.service';
import msalInstance from './services/msal.service';
import 'tippy.js/dist/tippy.css';

import UnderConstructionPage from './pages/underConsruction/UnderConstructionPage';

import Home from './pages/home/Home';
import HRHG from './pages/hrh/HRHG';
import HRH03 from './pages/hrh/HRH03';
import HRH04 from './pages/hrh/HRH04';
/** import HRH06 from './pages/hrh/HRH06'; */
/** import HRH08 from './pages/hrh/HRH08'; */
import HRH11 from './pages/hrh/HRH11';
import HRH12 from './pages/hrh/HRH12';
/** import VTT21 from './pages/vtt/VTT21'; */
import HealthCheck from './pages/healthCheck';

//Material UI
import Fab from '@mui/material/Fab';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';

import './App.css';

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = () => {
  const isUnderConstruction = false;

  return isUnderConstruction ? (
    <>
      <UnderConstructionPage />
      <Fab
        href="https://www.surveymonkey.com/r/JBS8JSY"
        target="_blank"
        variant="extended"
        style={{ backgroundColor: '#0c69b0', color: 'white' }}
        sx={{
          position: 'absolute',
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(3),
        }}
      >
        <ChatRoundedIcon sx={{ mr: 1 }} />
        Report an issue
      </Fab>
    </>
  ) : (
    <Router>
      <QueryClientProvider client={queryClient}>
        <MsalProvider instance={msalInstance}>
          <PageLayout>
            <Routes>
              <Route path="/buildings/hrh/gfloor" element={<HRHG />} />
              <Route path="/buildings/hrh/3rdfloor" element={<HRH03 />} />
              <Route path="/buildings/hrh/4thfloor" element={<HRH04 />} />
              <Route path="/buildings/hrh/4thfloor" element={<HRH04 />} />
              <Route path="/healthCheck" element={<HealthCheck/>}/>
              {/*  <Route path="/buildings/hrh/6thfloor"  element={<HRH06 />} />  */}
              {/*  <Route path="/buildings/hrh/8thfloor"  element={<HRH08 />} />  */}
              <Route path="/buildings/hrh/11thfloor" element={<HRH11 />} />
              <Route path="/buildings/hrh/12thfloor" element={<HRH12 />} />
              {/*  <Route path="/buildings/vtt/21stfloor"  element={<VTT21 />} />  */}
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Home />} />
            </Routes>
            <Fab
              href="https://www.surveymonkey.com/r/JBS8JSY"
              target="_blank"
              variant="extended"
              style={{ backgroundColor: '#0c69b0', color: 'white' }}
              sx={{
                position: 'absolute',
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(3),
              }}
            >
              <ChatRoundedIcon sx={{ mr: 1 }} />
              Report an issue
            </Fab>
          </PageLayout>
        </MsalProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default App;
