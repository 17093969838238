import React, { useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
//import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import './Navbar.css';

export default function Navbar() {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  //const { instance } = useMsal();
  return (
    <>
      <nav className="nav">
        <div className="nav-logo-section">
          <Link className="find-huddle-logo" to="/buildings/hrh/gfloor">
            FindHuddle
          </Link>
        </div>
        {/*<div className="nav-signout-btn">
          <AuthenticatedTemplate>
            <Button
              variant="secondary"
              onClick={() =>
                instance.logoutRedirect({
                  postLogoutRedirectUri: '/',
                  mainWindowRedirectUri: '/',
                })
              }
            >
              Sign out
            </Button>
          </AuthenticatedTemplate>
            </div>*/}
      </nav>
      {true && (
        <Sidebar
          sidebarVisible={sidebarVisible}
          setSidebarVisible={setSidebarVisible}
        />
      )}
    </>
  );
}
