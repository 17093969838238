import React from 'react';
import { useQuery } from 'react-query';
import adapter from '../../services/adapter.service';
import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs'
import { protectedResources, loginRequest } from '../../config/authConfig';
// import { Link } from 'react-router-dom';

const statusCard = (room,lastOnline,building) => {

    const now = Date.now()
    const lastOnlineConvert = new Date(lastOnline)

    const dif = now - lastOnlineConvert;

    const hour = dif/(60000 * 60)

    let state = 'rgb(34 197 94)'
    if(hour > 24){
        state = 'grey'
    } else if (hour < 0){
        state = 'grey'
    }


    return (
      <div>
        <div key={room} className='flex border-4 border-gray-600 rounded-xl w-40 m-2'>
            <div className='border-r-2  border-gray-400 w-3/4 p-1 '>
                <p className="font-bold px-2">
                    {room}
                </p>
                <p className='font-semibold px-2'>
                    {building}
                </p>
            </div>
            <div className='flex items-center justify-center w-1/4'>
                <BsIcons.BsCircleFill color={state}/>
            </div>
        </div>
        <div>
            {lastOnlineConvert.toDateString()}{lastOnlineConvert.toLocaleTimeString()} 
        </div>
      </div>
    )
}

function floorSepperate(data) {
    let floorState = ''
    const reactElement = []
    let StatusElement = []
    data.forEach((room) => {
        if (room.building === "HRH"){
            if(room.floor === floorState){
                StatusElement.push(statusCard(room.room, room.lastOnline, room.building))
            } else {
                if (StatusElement.length > 0){
                    reactElement.push(StatusElement)
                    StatusElement = []
                }
                floorState = room.floor
                reactElement.push([<div className='font-bold text-2xl' key={floorState}>
                    {floorState}<span className='font-bold text-lg'>F</span>
                </div>])
                StatusElement.push(statusCard(room.room, room.lastOnline, room.building))
            }
        }
    })

    reactElement.push(StatusElement)

    return (
        <div>
            {reactElement.map((value,index)  => (<div className='flex py-1 w-full flex-wrap' key={index}>{value}</div>))}
        </div>
    )
}

const HealthCheck = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || 'homeAccountId');
    if (account && inProgress === 'none' && !adapter.accessToken) {
        console.log('test')
      instance
        .acquireTokenSilent({
          scopes: protectedResources.devicesApi.scopes,
          account: account,
        })
        .then((response) => {
          adapter.accessToken = response.accessToken;
        })
        .catch((error) => {
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === 'none') {
              instance
                .acquireTokenPopup({
                  scopes: protectedResources.devicesApi.scopes,
                })
                .then((response) => {
                  adapter.accessToken = response.accessToken;
                })
                .catch((error) => console.log(error));
            }
          }
        });
    }
  
    const {isLoading,error,data} = useQuery(
      `getRoomsInfo`,
      () =>
        adapter.instance
          .get(`/api/devices`, {
            headers: { Authorization: 'Bearer ' + adapter.accessToken },
          })
          .then((res) => res.data),
      {
        // Refetch the data every 10 seconds
        refetchInterval: '10000',
      }
    );


    if (isLoading)
    return (
      <div className="find-huddle-layout">
        <AiIcons.AiOutlineLoading3Quarters className="icon-spin find-huddle-loading" />
      </div>
    );




    if (error) {
        console.log('An error has occurred: ' + error.message);
        instance.loginRedirect(loginRequest);
        return;
    }

    return (
       <div >
            {floorSepperate(data)}
        </div>
    );
};


export default HealthCheck;