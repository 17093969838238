import React from 'react';
import './Container.css';

export default function Container({ children }) {
  return (
    <article className="main-content">
      <div className="container">{children}</div>
    </article>
  );
}
